@import '_fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  @apply text-dark-blue;
}

.container {
  max-width: 700px;
  padding: 0 10px;
}

.btn-primary {
  /* Button */
  /*padding: 20px 32px;*/
}

/*********** Baseline, reset styles ***********/
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 25rem;
}

/* Removes default focus */
input[type='range']:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type='range']::-webkit-slider-runnable-track {
  background-color: #283897;
  border-radius: 0.5rem;
  height: 0.1rem;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  background-color: #283897;
  margin-top: -11px; /* Centers thumb on the track */
  border-radius: 1.5rem;
  height: 25px;
  width: 25px;
}

input[type='range']:focus::-webkit-slider-thumb {
  outline: 3px solid #283897;
  outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/
/* slider track */
input[type='range']::-moz-range-track {
  background-color: #283897;
  border-radius: 0.5rem;
  height: 0.1rem;
}

/* slider thumb */
input[type='range']::-moz-range-thumb {
  background-color: #283897;
  border: none; /*Removes extra border that FF applies*/
  border-radius: 1.5rem;
  margin-top: -10px; /* Centers thumb on the track */
  height: 25px;
  width: 25px;
}

input[type='range']:focus::-moz-range-thumb {
  outline: 3px solid #283897;
  outline-offset: 0.125rem;
}

input[type='range']::-moz-range-progress {
  background-color: #0098a6;
}
